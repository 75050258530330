import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import {sluger} from "../util/sluger"
const qs = require("query-string")

const Searches = ({ location, pageContext }) => {
  const parsed = qs.parse(location.search)
  //const searchText = location.state?.searchText ?? ""
  const searchText = !!parsed.q ? parsed.q : ""
  const { posts } = pageContext

  let totalCount = 0
  const filtered = (searchText.trim() !== "") ? posts.filter(
      edge =>
        !!edge.node.frontmatter.date &&
        edge.node.frontmatter.template === "blog-post" &&
        edge.node.frontmatter.title
          .trim()
          .match(new RegExp(searchText.trim(), "gi"))
    ) : null
  
  if(searchText.trim() !== "")
    totalCount = filtered.length

  let seoTitle = ""
  let searchHeader = ""
  if (totalCount === 0) {
    searchHeader = `No result found for "${searchText}"`
    seoTitle = "Search Result - Meek Code"
  } else {
    searchHeader = `${totalCount} search result${
      totalCount === 1 ? "" : "s"
    } found with "${searchText}"`

    seoTitle = `Search Result for ${searchText} - Meek Code`
  }

  return (
    <Layout className="blog-page">
      <SEO title={seoTitle}/>
      <div>
        <h2>{searchHeader}</h2>
        <ul>
          {(totalCount !== 0) ? filtered.map(({ node }) => {
            const { slug } = node.frontmatter
            const { title } = node.frontmatter
            return (
              <li key={slug ? slug : sluger(title,"/blog/") }>
                <Link to={slug ? slug : sluger(title,"/blog/") }>{title + " - " + node.frontmatter.date}</Link>
              </li>
            ) 
          }) :"" } 
        </ul>
      </div>
    </Layout>
  )
}

export default Searches
